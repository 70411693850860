<template>
  <div class="rtcr">
    <h3 class="mb-4">TCR Sample Plan Submission</h3>
    <b-button class="no-print" variant="primary" @click="toggleMap">
      <b-icon-map />
      {{ show_map ? 'Hide Map' : 'Show Map' }}
    </b-button>
    <b-button class="no-print ml-2" variant="primary" @click="print">
      <b-icon-printer />
      Print a Copy</b-button
    >
    <div id="printMe" class="mt-4" v-if="sample_plan">
      <ul class="sample_plan">
        <li class="mb-2">
          <strong>PWS:</strong> &nbsp;{{ sample_plan.pws_id }}
          <!-- PWS Name not submitted -->
          <span v-if="sample_plan.pws_id == pws_id">
            {{ pws_name }}
          </span>
        </li>
        <li class="mb-2">
          <strong>Plan Type:</strong> &nbsp;{{
            plan_type_txt[sample_plan.plan_type_code]
          }}
        </li>
        <li class="mb-2">
          <strong>Authenticated User:</strong> &nbsp;{{ sample_plan_meta.user_name }}
        </li>
        <li class="mb-2">
          <strong>Signed Name:</strong> &nbsp;{{ sample_plan.signame }}
        </li>
        <li class="mb-2">
          <strong>Submitted:</strong> &nbsp;{{ sample_plan_meta.submission_ts }}
        </li>
        <li class="mb-2">
          <strong>Transaction ID:</strong> &nbsp;{{ sample_plan_meta.id }}
        </li>
        <li class="mb-2">
          <strong>Comments:</strong> &nbsp;{{ sample_plan.comments }}
        </li>
        <li v-if="sample_plan.plan_type_code == '3'">
          <strong>SOP File:</strong>
          <a target="_blank" :href="'/api/rtcr-plan-sop?plan_id=' + sample_plan_meta.id">
          Standard Operating Procedure
          </a>
        </li>
      </ul>
      <p>
        <strong>{{ pws_id }} {{ pws_name }}</strong>
      </p>

      <div id="gmap" v-if="show_map">
      <GmapMap
        ref="spmap"
        :center="map_center"
        :zoom="12"
        map-type-id="terrain"
        :options="{ gestureHandling: 'greedy' }"
        style="width: 100%; height: 60vh"
        @shown="onShow"
      >
        <GmapMarker
          :key="index"
          :label="spint(m.title)"
          :title="m.address"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="false"
          :draggable="false"
          @click="center = m.position"
        />
      </GmapMap>
      </div>

      <div class="mt-4">
      <h4>Sample Points</h4>
      <b-table
        small
        striped
        bordered
        :fields="sp_fields"
        :items="sample_plan.sample_points"
      >
      </b-table>

      <div class="mt-4">
      <h4>Wells</h4>
      <b-table
        small
        striped
        bordered
        :fields="well_fields"
        :items="sample_plan.wells"
      >
      </b-table>
      </div>

    </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue"
import VueHtmlToPaper from "vue-html-to-paper"
import { SessionMixin } from "../mixins"
import * as VueGoogleMaps from "vue2-google-maps"


const average = (array) => array.reduce((a, b) => a + b) / array.length


const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
  ],
  timeout: 2000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title // override the window title
}

Vue.use(VueHtmlToPaper, options)

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDcZNa-cxgUyA6Hfqr772Sevv5XdbOpqFs"
    //key: "AIzaSyDxx8AnfmtH6hU7TUZiyvyY-hhwVa1V4Po",
    //libraries: "places"
  }
  //installComponents: true
})

export default {
  name: "RTCRReport",
  mixins: [SessionMixin],
  data() {
    return {
      items: [
        { age: 40, first_name: "Dickerson", last_name: "Macdonald" },
        { age: 21, first_name: "Larsen", last_name: "Shaw" },
        { age: 89, first_name: "Geneva", last_name: "Wilson" },
        { age: 38, first_name: "Jami", last_name: "Carney" }
      ],
      plan_type_txt: {
        1: "RTCR Option 1",
        2: "RTCR Option 2",
        3: "RTCR Option 3"
      },
      show_map: true,
      sample_plan: null,
      sample_plan_meta: {},
      sp_fields: [
        { key: "sp_id", label: "ID" },
        { key: "status", label: "Status" },
        { key: "address", label: "Address" },
        { key: "city", label: "City" },
        { key: "zipcode", label: "Zip" },
        { key: "description", label: "Description" },
        { key: "note", label: "Note" }
      ],
      well_fields: [
        { key: "sp_id", label: "ID" },
        { key: "directions_text", label: "Directions" },
      ],
      markers: [],
      map_center: { lat: 0, lng: 0}
    }
  },
  created() {
    console.log('created')
    console.log(this.$route.params)
    this.fetchSamplePlan(this.$route.params.plan_id)
  },
  mounted() {
    console.log('mounted')
    setTimeout(this.onShow, 2000)
  },
  methods: {
    async print() {
      await window.print()
    },
    spint(sp) {
      if (!sp) {
        return ""
      }
      if (sp.startsWith('WL')) {
        return sp
      }
      let t = sp.substr(2, 10)
      return parseInt(t).toString()
    },
    toggleMap() {
      console.log('toggle map')
      this.show_map = this.show_map ? false : true
      //this.fitBounds(this.$refs.spmap)
      if (this.show_map) {
        setTimeout(this.onShow, 2000)
      }
    },
    async fetchSamplePlan(plan_id) {
      const path = process.env.VUE_APP_API_URI + "/rtcr-plan"
      let lats = []
      let lons = []
      try {
        let response = await this.axios.get(path, {
          params: { plan_id: plan_id }
        })
        //console.log(response)
        if (response.data.sample_plan) {
          let sp = response.data.sample_plan
          let data_signed = JSON.parse(sp.data_signed)
          //console.log(data_signed)
          this.sample_plan = data_signed
          //this.sample_plan = response.data.sample_plan
          this.sample_plan_meta.user_name = sp.portal_user_name
          this.sample_plan_meta.submission_ts = sp.submission_ts
          this.sample_plan_meta.id = sp.id
          data_signed.sample_points.forEach((sp) => {
            let marker = {}
            marker.title = sp.sp_id
            if (sp.latitude) {
              marker.position = { lat: sp.latitude, lng:sp.longitude }
              marker.address = "Point"
            } else if (sp.geo_latitude) {
                marker.position = { lat: sp.geo_latitude, lng: sp.geo_longitude },
                marker.address = sp.address
            }
            if (sp.status == 'A') {
              this.markers.push(marker)
              lats.push(marker.position.lat)
              lons.push(marker.position.lng)
            }
          })
          data_signed.wells.forEach((sp) => {
            let marker = {}
            marker.title = sp.sp_id
            marker.address = sp.directions_text
            marker.position = { lat: sp.latitude_measure, lng:sp.longitude_measure }
            this.markers.push(marker)
            lats.push(marker.position.lat)
            lons.push(marker.position.lng)
          })
        }
        this.map_center.lat = average(lats)
        this.map_center.lng = average(lons)
        //console.log(this.markers)
        //console.log(this.$refs.spmap)
      } catch (error) {
        console.log(error)
      }
    },
    onShow() {
      //console.log(this.$refs.spmap)
      this.$refs.spmap.$mapPromise.then(() => {
        this.fitBounds(this.$refs.spmap)
      })
    },
    fitBounds(map) {
      console.log("fitting bounds")
      //if (!this.google_maps_initialized) return null
      let b = new window.google.maps.LatLngBounds()
      //console.log(b)
      this.markers.forEach((marker) => {
        b.extend(marker.position)
      })
      map.fitBounds(b)
    },
  }
}
</script>
<style scoped>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .pagebreak { page-break-before: always; }
}

.sample_plan {
  list-style: none;
  padding-left: 0;
}
</style>
